import Parse from "parse"
import React, { useState, useEffect } from 'react'
import { useHistory  } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"
import { Nav } from "../components/navbar"
import { OrderRow } from "../components/orders"
import { acceptOrder, completeOrder } from "../services/order"
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import DoneIcon from '@material-ui/icons/Done';

const TopGrid = styled.div`
    display: grid;
    grid-template-columns: auto 100px 160px;
    height: 40px;
`

const ProductGrid = styled.div`
    display: grid;
    grid-template-columns: 50px auto 50px;
    height: 40px;
    margin-top: 16px;
`

const OptionGrid = styled.div`
    display: grid;
    grid-template-columns: auto 50px;
    height: 40px;
    margin-left: 50px;
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

async function fetchData(order_object_id, setOrder) 
{
    const Order = Parse.Object.extend("Order");
    const orderQuery = new Parse.Query(Order);
    orderQuery.include("store,schedule,promoCode")
    const orderResult = await orderQuery.get(order_object_id)
    const _store = orderResult.get("store")
    const _status = orderResult.get("status")
    let _completion_time = orderResult.get("completionTime")
    const _delivery_information = orderResult.get("deliveryInformation")
    if (_delivery_information && _delivery_information.drop_time)
    {
        _completion_time = _delivery_information.drop_time
    }
    let _completion_date_string = ""
    let _completion_time_string = ""
    let _completion_time_title_string = ""
    let _schedule_delivery_note = ""
    let _schedule_collect_note = ""
    const _schedule = orderResult.get("schedule")

    let _status_title = ""
    if (_status === 0)
    {
        _status_title = "nowe"
    }
    else if (_status === 1)
    {
        _status_title = "zaakceptowane"
    }
    else if (_status === 2)
    {
        _status_title = 'w dostawie'
    }
    else if (_status === 3)
    {
        _status_title = "zakończone"
    }

    if (_schedule) 
    {
        const _date_complete_time = new Date(_completion_time * 1000)
        _completion_date_string =  _date_complete_time.toLocaleDateString("pl-PL")
        _completion_time_string = _date_complete_time.getHours() + ":" + ("0" + _date_complete_time.getMinutes()).substr(-2)
        if (_schedule.get("tag") === "today")
        {
            if (_status < 2)
            {
                _completion_time_title_string = "odbiór o: "
            }
            else if (_status === 2)
            {
                _completion_time_title_string = "dostarcz do: "
            }
        }
        else if (_schedule.get("tag") === "next_day")
        {
            _schedule_delivery_note = _schedule.get("deliveryNote")
            _schedule_collect_note = _schedule.get("collectNote")
        }
        else if (_schedule.get("tag") === "days")
        {
            _completion_time_title_string = "zaplanowane na:"
            _schedule_delivery_note = _schedule.get("deliveryNote")
            _schedule_collect_note = _schedule.get("collectNote")
        }
    } 
    else if (_delivery_information.pickup_time === 0)
    {
        if (_delivery_information.possible_pickup_time && _delivery_information.possible_pickup_time > 0) // time required to be accepted by the provider
        {
            const _date = new Date(_delivery_information.possible_pickup_time * 1000)
            _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
            _completion_time_title_string = "możliwy odbiór o: "
        } 
        else
        {
            _completion_time_string = "czas odbiory nie został ustalony" 
        }
    }
    else if (_delivery_information.pickup_time > 0) // time i defined.
    {
        if (_status < 2)
        {
            const _date = new Date(_delivery_information.pickup_time * 1000)
            _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
            _completion_time_title_string = "odbiór o: " 
        } 
        else if (_status === 2)
        {
            const _date = new Date(_completion_time * 1000)
            _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
            _completion_time_title_string = "dostarcz do: " 
        } 
    } 

    const customer_info = orderResult.get("customerInfo")
    let delivery_address_string = ""
    if (customer_info.city)
    {
        delivery_address_string = customer_info.city + ", "
    }
    if (customer_info.street)
    {
        delivery_address_string = delivery_address_string + customer_info.street + " "
    }
    if (customer_info.building_number)
    {
        delivery_address_string = delivery_address_string + customer_info.building_number
    }
    if (customer_info.flat_number)
    {
        delivery_address_string = delivery_address_string + " / " + customer_info.flat_number
    }

    let customer_phone_number = ""
    if (customer_info.phone_number)
    {
        customer_phone_number = customer_info.phone_number
    }

    let customer_delivery_note = ""
    if (customer_info.notes)
    {
        customer_delivery_note = customer_info.notes
    }

    const payment_info = orderResult.get("paymentInfo")
    let payment_type = ""
    if (payment_info.what_type)
    {
        if (payment_info.what_type === "card")
        {
            payment_type = "karta"
        }
        else if (payment_info.what_type === "cash")
        {
            payment_type = "gotówka"
        }
        else if (payment_info.what_type === "online")
        {
            payment_type = "online - zapłacone"
        }
    }

    const products = orderResult.get("products")
    let products_list = []
    for (let i=0; i < products.length; i++)
    {
        if (products[i].tag != "variant" && products[i].tag != "promotion" && products[i].tag != "free_delivery")
        {
            let _item = {
                quantity: products[i].quantity,
                name: products[i].name,
                price: products[i].price,
                options: []
            }
            for (let j=0; j < products.length; j++)
            {
                if (products[j].tag === "variant")
                {
                    if (products[i].uid === products[j].uid)
                    {
                       _item.options.push({
                            quantity: products[j].quantity,
                            name: products[j].name,
                            price: products[j].price,
                        }) 
                    }
                }
            }
            products_list.push(_item)
        }   
    }

    const order_data = {
        object_id: orderResult.id,
        // store_name: _store.get("name"),
        order_id: orderResult.get("orderId"),
        // products_quantity: orderResult.get("products").length,
        order_value: orderResult.get("orderValue"),
        status: _status,
        status_title: _status_title,
        delivery_price: orderResult.get("deliveryPrice"),
        // delivery_type: orderResult.get("deliveryType"),
        // status: orderResult.get("status"),
        completion_date: _completion_date_string,
        completion_time: _completion_time_string,
        completion_time_title: _completion_time_title_string,
        schedule_delivery_note: _schedule_delivery_note,
        schedule_collect_note: _schedule_collect_note,
        delivery_address: delivery_address_string,
        customer_phone_number: customer_phone_number,
        customer_delivery_note: customer_delivery_note,
        // payment_type: payment_type,
        products: products_list
    }

    order_data.total_cost = order_data.order_value + order_data.delivery_price

    setOrder(order_data)
};


export default function Start({ match }) {

  const [order, setOrder] = useState({})
  const classes = useStyles();
  const history = useHistory();

  function goBack()
  {
    history.goBack()
  }

  async function onAcceptOrderClick(event)
  {
    event.preventDefault()
    await acceptOrder(order.object_id, ()=>{ fetchData(match.params.order_object_id, setOrder) })
  }

  async function onCompleteOrderClick(event)
  {
    event.preventDefault()
    await completeOrder(order.object_id, ()=>{ fetchData(match.params.order_object_id, setOrder) })
  }

  useEffect(() => {
    if (match.params.order_object_id)
    {
        fetchData(match.params.order_object_id, setOrder) 
    }
  }, [])
  
  return (
    <div className={classes.root}>
        <Nav title={"Zamówienie"} />
        <main className={classes.content} >
        <Toolbar />
        <TopGrid>
            <h2 style={{margin:"0px"}}>
                ID: {order.order_id} | status: {order.status_title}
            </h2>
            <div>
                
            </div>
            {order.status != null && order.status === 0?<Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DoneIcon />}
                onClick={onAcceptOrderClick}
              >
                akceptuje
            </Button>:null}
            {order.status != null && order.status === 1?<Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<DoneIcon />}
                onClick={onCompleteOrderClick}
              >
                zrealizowane
            </Button>:null}


    
        </TopGrid>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
               <Paper className={classes.paper}>
                    <h3 style={{marginTop:"8px"}}>Dane klient</h3>
                    <p>Adres: <b>{order.delivery_address}</b></p>
                    <p>Telefon: <b>{order.customer_phone_number}</b></p>
                    <p>Notatka: <b>{order.customer_delivery_note}</b></p>
                    
               </Paper>

               <Paper className={classes.paper}>

                    <h3 style={{marginTop:"8px"}}>Termin realizacji:</h3>
                    <p>{order.completion_time_title}</p>
                    <p>data: <b>{order.completion_date}</b></p>
                    <p>godz.: <b>{order.completion_time}</b></p>
                    <p>notatka do dostawy: <b>{order.schedule_delivery_note}</b></p>
                    <p>notatka do odbioru: <b>{order.schedule_collect_note}</b></p>
            
               </Paper>


            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                {order.products?<> 
                    <h3 style={{marginTop:"8px"}}>Lista produktów:</h3>
                    {order.products.map(product => (
                        <>
                        <ProductGrid>
                        <p>x {product.quantity}</p>
                        <p><b>{product.name}</b></p>
                        <p><b>{product.price} zł</b></p>
                        </ProductGrid>

                        {product.options.map(option => (
                            <OptionGrid>

                            <p>{option.name}</p>
                            <p><b>{option.price} zł</b></p>
                            </OptionGrid>
                            
                        ))}
                        </>
                    ))}
                </>:null}

               </Paper>
               <Paper className={classes.paper}>

                    <h3 style={{marginTop:"8px"}}>Podsumowanie płatności:</h3>
                    <p>produkty: {order.order_value} zł</p>
                    <p>dostawa: {order.delivery_price} zł</p>
                    <p><b>Suma: {order.total_cost} zł</b></p>

               </Paper>
            </Grid>
        </Grid>
        </main>
    </div>
  )
}
