import Parse from "parse";

export const acceptOrder = async( order_object_id, callback ) => {
  let request_params = {
    orderObjectId: order_object_id
  }
  let result = await Parse.Cloud.run("orderIsAccepted", request_params)
  if (result)
  {
    if (callback)
    {
      callback()
    }
  }
};

export const completeOrder = async( order_object_id, callback ) => {
  let request_params = {
    orderObjectId: order_object_id,
    skip_push: true
  }
  let result = await Parse.Cloud.run("orderIsCollected", request_params)
  if (result)
  {
    if (callback)
    {
      callback()
    }
  }
  console.log(result)
};

export const cancelOrder = async( username, password, callback ) => {
  
};
