import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import AddOrder from './pages/AddOrder';
import Login from './pages/Login';
import Orders from './pages/Orders';
import Order from './pages/Order';
import MyAccount from './pages/MyAccount';
import Products from './pages/Products';

import { ThemeProvider, createTheme , makeStyles } from '@material-ui/core/styles';
import { createGlobalStyle } from "styled-components"
// import CookieConsent from 'react-cookie-consent-notification';

import Parse from 'parse';

// import TagManager from 'react-gtm-module'

import {color_primary, color_accent, main_font, deployment_type} from "./styles/globalstyle";

// const tagManagerArgs = {
//   gtmId: 'GTM-KMBQ23Z'
// }
// TagManager.initialize(tagManagerArgs)

Parse.initialize("APzdQAwxfu3wawCWWGedQ6ne2PlnDXeMJFY31cXS", "LSxq6Si6G6eWMn7sIGZyrVwtAd2pmquCaHOkUYWe");
Parse.serverURL = "https://zio.b4a.io/";

// DEV
// Parse.initialize("PAHRoleN7bsZBErF69sRYLwHTLFrQpxcRN5ZyRbB", "94DUOe5j8ZM2k8uisA8TnLboesbhaatcJB2tGOoe");
// Parse.serverURL = "https://ziodev.b4a.io/";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${main_font};
    background-color: rgba(250, 250, 250, 255);
  }

  h1, h2 {
    font-family: ${main_font};
    font-size: 28px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h3 {
    font-family: ${main_font};
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h4 {
    font-family: ${main_font};
    color: gray;
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`

const theme = createTheme({
  palette: {
    primary: {
      main: color_primary
    },
    secondary: {
      main: color_accent
    }
  }
});


const checkStatus = (status) => {
  if(status) {
    // To set a cookies
  }
};


export default function App() {
  return (
    <>
     <ThemeProvider theme={theme}>
      <Router>
        <GlobalStyle/>
          <div className="App">
            <Switch>
              <Route path="/" exact component={Login}></Route>
              <Route path="/orders" exact component={Orders}></Route>
              <Route path="/add_order" exact component={AddOrder}></Route>
              <Route path="/order/:order_object_id" exact component={Order}></Route>
              <Route path="/my_account" exact component={MyAccount}></Route>
              <Route path="/products" exact component={Products}></Route>

            </Switch>
          </div>
        </Router>
        {/*<CookieConsent 
          background={'#ffffff'}
          bottomPosition={true}
          buttonText={'OK'}
          buttonBackground={'#000000'}
          buttonColor={'#ffffff'}
          buttonFontSize={16}
          color={'#000000'}
          consentFunction={checkStatus}
          padding={20}
        >
          Ta strona wykorzystuje pliki cookie, aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. Zobacz: <a href={''} style={{ color: '#000000' }}>Polityka prywatności</a>
        </CookieConsent>*/}
      </ThemeProvider>
    </>
  );
}
