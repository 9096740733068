import Parse from "parse"
import React, { useState, useEffect } from 'react'
import { useHistory  } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"
import { Nav } from "../components/navbar"
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getStoresData, setStoresData } from "../services/store"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
}));

async function fetchProducts(setProducts, search_phrase, stores_data) 
{
    
    let _filert_stores = []

    const Order = Parse.Object.extend("Product");
    const query = new Parse.Query(Order);
    query.limit(250)
    // query.equalTo("storeObjectId", )
    query.descending("name")
    
    if (search_phrase && search_phrase !== "")
    {
        query.startsWith("name", search_phrase)
        for (let i = 0; i < stores_data.length; i++) // look for products from all your stores
        {
          _filert_stores.push(stores_data[i].object_id)
        }
    }
    else  
    {
      for (let i = 0; i < stores_data.length; i++)
      {
        if (stores_data[i].is_selected)
        {
          _filert_stores.push(stores_data[i].object_id)
        }
      }
    }
    query.containedIn("storeObjectId", _filert_stores)


    const results = await query.find();

    let _items = []
    for (let i = 0; i < results.length; i++) 
    {
      let _status = "ukryte"
      if (results[i].get("status") === 0)
      {
        _status = "widoczne"
      }
      _items.push({
        name: results[i].get("name"),
        price: results[i].get("price"),
        object_id: results[i].id,
        status: _status
      })
    }
    setProducts(_items)
};


export default function Products({ match }) {

  const [products, setProducts] = useState([])
  const [stores, setStores] = useState([])
  const [searchPhrase, setSearchPhrase] = useState("")

  const classes = useStyles();

  function getProducts(search_phrase, _stores_data)
  {
    fetchProducts(setProducts, search_phrase, _stores_data) 
  } 

  function handleStoreSelectedChange(event)
  {
    let _stores = [...stores]
    for (let i = 0; i < _stores.length; i++)
    {
      if (_stores[i].object_id === event.target.value)
      {
        _stores[i].is_selected = event.target.checked
      }
    }
    setStores(_stores)
    getProducts("", _stores)
    setStoresData(_stores)
  }

  function searchChangeHandler(event) 
  {
    event.preventDefault();
    setSearchPhrase(event.target.value)
    if (event.target.value === "")
    {
        getProducts("", stores)
    }  
  }
  function searchSubmitHandler(event) 
  {
    if (event.key === 'Enter') 
    {
        event.preventDefault();
        getProducts(searchPhrase, stores)
    }
  }

  useEffect(() => {
    const _stores_data = getStoresData()
    setStores(_stores_data)
    getProducts("", _stores_data)
  }, [])
  
  return (
    <div className={classes.root}>
        <Nav title={"Zamówienia"} />
        <main className={classes.content} >
        <Toolbar />
            <FormGroup row>
              {stores.map((store) => (
                <FormControlLabel key={store.object_id} control={<Checkbox checked={store.is_selected} onChange={handleStoreSelectedChange} value={store.object_id} name="checkedC" />} label={store.name} />
              ))}
              
            <TextField 
                id="standard-search" 
                label="szukaj po nazwie" 
                type="search" 
                variant="outlined" 
                style = {{marginRight:"8px", width:"150px"}} 
                onChange={searchChangeHandler}
                onKeyPress={searchSubmitHandler}
                value={searchPhrase}
            />
            <IconButton style = {{marginRight:"32px"}} onClick={(e)=>{e.key = "Enter"; searchSubmitHandler(e)} } >
              <SearchIcon fontSize="large" />
            </IconButton>
            </FormGroup>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nazwa</TableCell>
                    <TableCell align="right">cena&nbsp;(zł)</TableCell>
                    <TableCell align="right">dostepność</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product.object_id}>
                      <TableCell component="th" scope="row">
                        {product.name}
                      </TableCell>
                      <TableCell align="right">{product.price}</TableCell>
                      <TableCell align="right">{product.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </main>
        
    </div>
  )
}
