import styled from "styled-components"
import Fade from '@material-ui/core/Fade';

const Order = styled.div`
	height: auto;
	border-radius:6px;
	box-shadow: ${({isActive}) => (isActive === true ? `0px 0px 6px 0px grey;` : `0px 0px 2px 0px grey;` )}
	transition-duration: .3s;
	margin-bottom: 16px;
	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 3px 0px grey;
	}
`

const Container = styled.div`
	display: grid;
	grid-template-columns: auto 128px;
`

const TextContainer = styled.div`
	padding: 16px;
`

const StoreName = styled.div`
	text-align: left;
	font-size: 18px;
`

const Label = styled.div`
	text-align: left;
	margin-top: 4px;
	color: gray;
	font-size: 14px;
`

const DeliveryTime = styled.div`
	text-align: center;
	margin-top: 6px;
	font-size: 32px;
	font-weight:800;
	padding-right: 6px;
`

const DeliveryTimeLabel = styled.div`
	text-align: center;
	margin-top: 40px;
	font-size: 18px;
`

const DeliveryTimeNote = styled.div`
	text-align: center;
	margin-top: 6px;
	font-size: 18px;
`

export function OrderRow({orders, onClickCallback})
{
	return <>
		{orders.map(order => (
			<Fade in={true} timeout={1500}>
				<Order key={order.object_id} onClick={()=>{ onClickCallback(order.object_id) } }>
					<Container>
						<TextContainer>
							<StoreName >
								<b>{order.store_name}</b>
							</StoreName>
							<Label >
								kod odbioru: <b>{order.order_id}</b>
							</Label>
							<Label >
								adres dostawy: <b>{order.delivery_address}</b>
							</Label>
							<Label >
								telefon klienta: <b>{order.customer_phone_number}</b>
							</Label>
							<Label >
								płatność: <b>{order.payment_type}</b>
							</Label>
						
							<Label>
								Termin: {order.completion_date} | {order.completion_time}
							</Label>
						</TextContainer>
					</Container>
				</Order>
			</Fade>
			))
		}
	</>
}