import React from 'react';
import { logout } from "../services/user"
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useHistory  } from "react-router-dom"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ListIcon from '@material-ui/icons/List';
import Button from '@material-ui/core/Button';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export function Nav({title}) {
  const classes = useStyles();
  const history = useHistory();

  function goTo(go_to)
  {
    history.push("/"+go_to)
  }

  function logoutUser()
  {
    logout(()=>{ goTo("") })
  }
  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
          
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem button key={"orders"} onClick={()=>{goTo("orders")}}>
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary={"Zamówienia"} />
            </ListItem>

            <ListItem button key={"products"} onClick={()=>{goTo("products")}}>
              <ListItemIcon> <ListIcon /></ListItemIcon>
              <ListItemText primary={"Produkty"} />
            </ListItem>
            <Button onClick={logoutUser} style={{marginTop: "700px", width: '100%'}} color="inherit">wyloguj</Button>
          </List>
        </div>
      </Drawer>
    </>
  );
}