import Parse from "parse"
import React, { useState, useEffect } from 'react'
import { useHistory  } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"
import { Nav } from "../components/navbar"
import Container from '@material-ui/core/Container';
import { OrderRow } from "../components/orders"
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

async function fetchData(setOrdersStatusZero, setOrdersStatusOne, setOrdersStatusTwo, setOrdersStatusThree, show_completed_orders, search_phrase) 
{
    const Order = Parse.Object.extend("Order");
    const orderQuery = new Parse.Query(Order);
    orderQuery.limit(25)
    orderQuery.include("store")
    if (search_phrase && search_phrase !== "")
    {
        orderQuery.equalTo("orderId", search_phrase)
    }
    else  
    {
        if (show_completed_orders)
        {
            orderQuery.lessThan("status", 6)
            orderQuery.greaterThan("status", 2)
        }
        else  
        {
            orderQuery.lessThan("status", 3)
        }
    }
    orderQuery.descending("completionTime")
   


    const orderResults = await orderQuery.find();
   
    let _orders_status_0 = []
    let _orders_status_1 = []
    let _orders_status_2 = []
    let _orders_status_3 = []
    for (let i = 0; i < orderResults.length; i++) 
    {
        // console.log(orderResults[i])
        const _store = orderResults[i].get("store")
        const _status = orderResults[i].get("status")
        let _completion_time = orderResults[i].get("completionTime")
        const _delivery_information = orderResults[i].get("deliveryInformation")
        if (_delivery_information && _delivery_information.drop_time)
        {
            _completion_time = _delivery_information.drop_time
        }
        let _completion_date_string = ""
        let _completion_time_string = ""
        let _completion_time_title_string = ""
        let _schedule_delivery_note = ""
        let _schedule_collect_note = ""
        const _schedule = orderResults[i].get("schedule")

        let _ststus_title = ""
        if (_status === 0)
        {
            _ststus_title = "nowe"
        }
        else if (_status === 1)
        {
            _ststus_title = "do odbioru"
        }
        else if (_status === 2)
        {
            _ststus_title = 'w dostawie'
        }
        else if (_status === 3)
        {
            _ststus_title = "zakończone"
        }

        if (_schedule) 
        {
            const _date_complete_time = new Date(_completion_time * 1000)
            _completion_date_string =  _date_complete_time.toLocaleDateString("pl-PL")
            _completion_time_string = _date_complete_time.getHours() + ":" + ("0" + _date_complete_time.getMinutes()).substr(-2)
            if (_schedule.get("tag") === "today")
            {
                if (_status < 2)
                {
                    const _date = new Date(_delivery_information.pickup_time * 1000)
                    _completion_date_string =  _date.toLocaleDateString("pl-PL")
                    _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                    _completion_time_title_string = "odbiór o: "
                }
                else if (_status === 2)
                {
                    _completion_date_string =  _date_complete_time.toLocaleDateString("pl-PL")
                    _completion_time_string = _date_complete_time.getHours() + ":" + ("0" + _date_complete_time.getMinutes()).substr(-2)
                    _completion_time_title_string = "dostarcz do: "
                }
            }
            else if (_schedule.get("tag") === "next_day")
            {
                _completion_time_string = _completion_date_string
                _schedule_delivery_note = _schedule.get("deliveryNote")
                _schedule_collect_note = _schedule.get("collectNote")
            }
        } 
        else if (_delivery_information && _delivery_information.pickup_time === 0)
        {
            if (_delivery_information.possible_pickup_time && _delivery_information.possible_pickup_time > 0) // time required to be accepted by the provider
            {
                const _date = new Date(_delivery_information.possible_pickup_time * 1000)
                _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                _completion_time_title_string = "możliwy odbiór o: "
            } 
            else
            {
                _completion_time_string = "czas odbiory nie ustalony" 
            }
        }
        else if (_delivery_information && _delivery_information.pickup_time > 0) // time i defined.
        {
            if (_status < 2)
            {
                const _date = new Date(_delivery_information.pickup_time * 1000)
                _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                _completion_time_title_string = "odbiór o: " 
            } 
            else if (_status === 2)
            {
                const _date = new Date(_completion_time * 1000)
                _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                _completion_time_title_string = "dostarcz do: " 
            } 
        } 
    
        const delivery_address = orderResults[i].get("customerInfo")
        let delivery_address_string = ""
        if (delivery_address.city)
        {
            delivery_address_string = delivery_address.city + ", "
        }
        if (delivery_address.street)
        {
            delivery_address_string = delivery_address_string + delivery_address.street + " "
        }
        if (delivery_address.building_number)
        {
            delivery_address_string = delivery_address_string + delivery_address.building_number
        }
        if (delivery_address.flat_number)
        {
            delivery_address_string = delivery_address_string + " / " + delivery_address.flat_number
        }

        let customer_phone_number = ""
        if (delivery_address.phone_number)
        {
            customer_phone_number = delivery_address.phone_number
        }

        const payment_info = orderResults[i].get("paymentInfo")
        let payment_type = ""
        if (payment_info.what_type)
        {
            if (payment_info.what_type === "card")
            {
                payment_type = "karta"
            }
            else if (payment_info.what_type === "cash")
            {
                payment_type = "gotówka"
            }
            else if (payment_info.what_type === "online")
            {
                payment_type = "online - zapłacone"
            }
      
        }
        const order_data = {
            object_id: orderResults[i].id,
            store_name: _store?_store.get("name"):"",
            order_id: orderResults[i].get("orderId"),
            products_quantity: orderResults[i].get("products").length,
            order_value: orderResults[i].get("orderValue"),
            delivery_type: orderResults[i].get("deliveryType"),
            status: orderResults[i].get("status"),
            completion_date: _completion_date_string,
            completion_time: _completion_time_string,
            completion_time_title: _completion_time_title_string,
            schedule_delivery_note: _schedule_delivery_note,
            schedule_collect_note: _schedule_collect_note,
            delivery_address: delivery_address_string,
            customer_phone_number: customer_phone_number,
            payment_type: payment_type,
            ststus_title: _ststus_title
        }

        if (_status === 0)
        {
            _orders_status_0.push(order_data)
        }
        else if (_status === 1)
        {
            _orders_status_1.push(order_data)
        }
        else if (_status === 2)
        {
            _orders_status_2.push(order_data)
        }
        else if (_status > 2)
        {
            _orders_status_3.push(order_data)
        }
    }
    setOrdersStatusZero(_orders_status_0)
    setOrdersStatusOne(_orders_status_1)
    setOrdersStatusTwo(_orders_status_2)
    setOrdersStatusThree(_orders_status_3)

};


export default function Start({ match }) {

  const [showCompletedOrders, setShowCompletedOrders] = useState(false)
  const [ordersStatusZero, setOrdersStatusZero] = useState([])
  const [ordersStatusOne, setOrdersStatusOne] = useState([])
  const [ordersStatusTwo, setOrdersStatusTwo] = useState([])
  const [ordersStatusThree, setOrdersStatusThree] = useState([])
  const [searchPhrase, setSearchPhrase] = useState("")

  const classes = useStyles();
  const history = useHistory();

  function onAddOrderClicked()
  {
    history.push("/add_order")
  }

  function onorderRowClick(order_object_id)
  {
    history.push("/order/"+order_object_id)
  }


  function getOrders(show_completed_orders, search_phrase)
  {
    fetchData(setOrdersStatusZero, setOrdersStatusOne, setOrdersStatusTwo, setOrdersStatusThree, show_completed_orders, search_phrase) 
  } 

  function handleChange(event)
  {
    setSearchPhrase("")
    setShowCompletedOrders(event.target.checked);
    getOrders(event.target.checked, "")
  };

  function searchChangeHandler(event) 
  {
    event.preventDefault();
    setSearchPhrase(event.target.value)
    if (event.target.value === "")
    {
        getOrders(false, "")
    }  
  }
  function searchSubmitHandler(event) 
  {
    if (event.key === 'Enter') 
    {
        event.preventDefault();
        getOrders(false, searchPhrase)
    }
  }

  useEffect(() => {
    getOrders(showCompletedOrders, "")
  }, [])
  
  return (
    <div className={classes.root}>
        <Nav title={"Zamówienia"} />
        <main className={classes.content} >
        <Toolbar />
   
        <TextField 
            id="standard-search" 
            label="szukaj po ID" 
            type="search" 
            variant="outlined" 
            style = {{marginRight:"8px", width:"150px"}} 
            onChange={searchChangeHandler}
            onKeyPress={searchSubmitHandler}
            value={searchPhrase}
        />
        <IconButton style = {{marginRight:"32px"}} onClick={(e)=>{e.key = "Enter"; searchSubmitHandler(e)} } >
          <SearchIcon fontSize="large" />
        </IconButton>

        <FormControlLabel
            control={<Checkbox checked={showCompletedOrders} onChange={handleChange} name="checkedA" />}
            label="pokaż zakończone zamówienia"
        />
        {ordersStatusZero.length===0&&ordersStatusOne.length===0&&ordersStatusTwo.length===0&&ordersStatusThree.length===0?<p>Brak aktywnych zamówień</p>:null}
        {ordersStatusZero.length>0?<>
            <h2 style={{color:"gray"}}>Nowe</h2>
            <OrderRow orders={ordersStatusZero} onClickCallback={onorderRowClick}/>
            </>:null}
        {ordersStatusOne.length>0?<>
            <h2 style={{color:"gray"}}>Zaakceptowane</h2>
            <OrderRow orders={ordersStatusOne} onClickCallback={onorderRowClick}/>
            </>:null}
        {ordersStatusTwo.length>0?<>
          <h2 style={{color:"gray"}}>W dostawie</h2>
          <OrderRow orders={ordersStatusTwo} onClickCallback={onorderRowClick}/>
          </>:null}
        {ordersStatusThree.length>0?<>
          <h2 style={{color:"gray"}}>Zakończone</h2>
          <OrderRow orders={ordersStatusThree} onClickCallback={onorderRowClick}/>
          </>:null}
        </main>
    </div>
  )
}
