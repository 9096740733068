import Parse from "parse";

export const initStoresData = async() => {
    const Store = Parse.Object.extend("Store");
    const query = new Parse.Query(Store);
    let user_data = await Parse.User.current();
    query.equalTo("userObjectId", user_data.id)
    query.equalTo("isActive", true)
    const results = await query.find();

    let _stores = []

    for (let i = 0; i < results.length; i++)
    {
      _stores.push({
        object_id: results[i].id,
        name: results[i].get("name"),
        is_selected:false
      })
    }
    _stores[0].is_selected = true
    localStorage.setItem("stores", JSON.stringify(_stores) )
};


export function getStoresData()
{
    return JSON.parse(window.localStorage.getItem("stores") )
}

export function setStoresData(stores)
{
    localStorage.setItem("stores", JSON.stringify(stores) )
}
